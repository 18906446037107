import Image from 'react-bootstrap/Image';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


// CSS
import styles from "./css/TabbedComponent2.module.css";
import "./css/TabbedComponent.css";

// Images
import imageSpatial01 from "./images/spatial-gallery-desktop-p-800.png";
import imageSpatial02 from "./images/spatial-gallery-responsive.png";
import imageSpatial03 from "./images/spatial-gallery-app-p-800.png";



function TabbedComponent2() {
  return (
    <Tabs
      defaultActiveKey="desktop"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="desktop" title="Desktop">

        <div>  
          <p className={`${styles.paragraphDark} ${styles.strong}`}>Desktop browser (recommended):</p>
          <p className={`${styles.paragraphDark}`}>1. Visit Spatial.io and Log in.</p>
          <p className={`${styles.paragraphDark}`}>2. Create your avatar and choose a Space.</p>
          <p className={`${styles.paragraphDark}`}>3. In your space, click the blue + 'Add content' button (bottom of the screen).</p>
          <p className={`${styles.paragraphDark}`}>4. Tab across to 'NFTS' and click on the 'Polygon' network to see your NFTS (Digital Collectibles).</p>
          <p className={`${styles.paragraphDark}`}>5. Choose an NFT (Digital Collectibles) then drag it to position it in your Space.</p>

          <div style={{height: '20px'}}></div>

          <Image src={imageSpatial01} className={styles.imageOpensea01}></Image>
        </div>

      </Tab>
      <Tab eventKey="mobile" title="Mobile">

        <div>
          <p className={`${styles.paragraphDark} ${styles.strong}`}>Mobile browser (currently limited):</p>
          {/* <p className={`${styles.paragraphDark}`}>1. Visit OpenSea and Connect Wallet.</p> */}
          {/* <p className={`${styles.paragraphDark}`}>2. Change the NFT list from 'Collected' to 'Hidden'.</p> */}
          {/* <p className={`${styles.paragraphDark}`}>3. Tap the three-dot menu on the bottom left corner of the NFT you'd like to unhide, and select Unhide.</p> */}

          <p className={`${styles.paragraphDark}`}>
            To access and customize your Space on mobile, you must use the 
            <a 
              href='https://support.spatial.io/hc/en-us/articles/360049505792-Spatial-s-Mobile-App-on-iOS-and-Android-'
              className={styles.linkText}
              target="_blank"
            > Spatial App.</a>
          </p>

          <div style={{height: '20px'}}></div>

          <Image src={imageSpatial02} className={styles.imageOpensea01}></Image>
        </div>

      </Tab>
      <Tab eventKey="app" title="App">

        <div>
          <p className={`${styles.paragraphDark} ${styles.strong}`}>Spatial App:</p>
          <p className={`${styles.paragraphDark}`}>Currently the App only allows you to visit Spaces and view existing Digital Collectibles or gallery pieces. To put a Digital Collectible up for display, please access Spatial.io via your desktop web browser.</p>

          <div style={{height: '20px'}}></div>

          <Image src={imageSpatial03} className={styles.imageOpensea01}></Image>
        </div>

      </Tab>
    </Tabs>
  );
}

export default TabbedComponent2;