import React from "react";
import { 
  Button,
} from "react-bootstrap";

import styles from "./css/WalletConnectorForm.module.css";



function WalletConnectorForm(props) {
  console.log("WalletConnectorForm:::2022-06-27");

  // State Variables:
  const walletAddress = props.walletAddress;


  var body;
  console.log('walletAddress:::2022-07-13', walletAddress);
  if (walletAddress.length > 0) {
    let walletButtonText = (
      "Connected: " +
      String(walletAddress).substring(0, 6) +
      "..." +
      String(walletAddress).substring(38)
    );

    body = (
      <>
        <Button variant="outline-primary" className={styles.connectButton}>
          { walletButtonText }
        </Button>
      </>
    );
    
  } else {
    body = (<></>);
  }

  return body;

}

export default WalletConnectorForm;