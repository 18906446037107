import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

// CSS
import styles from "./css/Minted.module.css";

// Components
import MintedTemplate from "./MintedTemplate";


const BASE_URL_API = process.env.REACT_APP_BASE_URL_API;








function Minted() {
  // console.log('Minted:::2022-07-14');
  const [polygonscanTransaction, setPolygonscanTransaction] = useState('');
  var params = {};


  processUrlParams();
  
  useEffect(() => { //TODO: implement
    fetchProduct();

    async function fetchProduct() {
      // console.log('fetchProduct():::2022-07-14');
      const product_uuid = params.uuid;
      await retrieveProductFromDatabase(product_uuid);
    
      async function retrieveProductFromDatabase(product_uuid) {

        let url = `${ BASE_URL_API }/api/v0/minter/products/${product_uuid}`;
        if (params.status === 'test') url = `${ BASE_URL_API }/api/v0/minter/test_nft_products/${product_uuid}`;
  
        await axios.get(
          url,
          // {
          //   uuid: productObj.uuid,
          // },
          {}
        ).then(function (response) {
          const payload = response.data.payload;
          // console.log('response.data:::2022-07-14', response.data);
          // console.log('minter_transaction:::2022-07-14', response.data.certify_product.mint_transaction)
          setPolygonscanTransaction(response.data.certify_product.mint_transaction);

        })
        .catch(function (error) {
            console.log(error)
        });
      }
    
    }

  }, []);


  function processUrlParams() {
    const queryString = window.location.search;
    console.log('queryString', queryString);
    const urlParams = new URLSearchParams(queryString);
    params["uuid"] = urlParams.get('uuid');
    params["status"] = urlParams.get('status');
  }



  return (
    <>
      <MintedTemplate
        polygonscanTransaction={polygonscanTransaction}
      ></MintedTemplate>
    </>
  );
}



export default Minted;