import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

import MinterTemplate from '../../components/templates/MinterTemplate';
import styles from "./Minter.module.css";


const BASE_URL_API = process.env.REACT_APP_BASE_URL_API;



function Minter() {
  // console.log('Minter:::2022-07-05');
  const navigate = useNavigate();


  const [isFetching, setIsFetching] = useState(true);
  const [store, setStore] = useState(null);
  const [product, setProduct] = useState({});
  const [order, setOrder] = useState({});
  const [nftDetails, setNftDetails] = useState({});
  const [claimProductResponse, setClaimProductResponse] = useState({});
  const [walletAddress, setWalletAddress] = useState('');

  var params = {};
  var productObj = {
    uuid: '',
  };
  processUrlParams();


  useEffect(() => { //TODO: implement

    getCurrentWalletConnected();
    addWalletListener();
    fetchData();


    async function retrieveProductFromDatabase() {
      let url = `${ BASE_URL_API }/api/v0/minter/products/${productObj.uuid}`;
      if (params.status === 'test') url = `${ BASE_URL_API }/api/v0/minter/test_nft_products/${productObj.uuid}`;

      await axios.get(
        url,
        // {
        //   uuid: productObj.uuid,
        // },
        {}
      ).then(function (response) {
        const payload = response.data.payload;
        let temp = {};
        temp.product = response.data.certify_product;
        setStore(response.data.store);
        setOrder(response.data.my_order);
        setProduct(response.data.certify_product);
        setClaimProductResponse(response.data);
        setNftDetails(payload.nft_details);
        setTimeout(() => {
          setIsFetching(false);
        }, 2000);

        if (temp.product.mint_transaction !== null || temp.product.claim_status === 'claimed') {
          let urlMinted = `/minted?uuid=${temp.product.uuid}`;
          if (params.status === 'test') urlMinted += `&status=test`;
          navigate(urlMinted, { replace: true });
        }
    
      })
      .catch(function (error) {
          console.log(error)
      });
    }

    async function fetchData() {
      await retrieveProductFromDatabase();
    }
  }, [productObj.uuid]);




  async function processUrlParams() {
    // console.log("processUrlParams():::2022-07-06");
    const queryString = window.location.search;
    console.log('queryString', queryString);
    const urlParams = new URLSearchParams(queryString);
    params["uuid"] = urlParams.get('uuid');
    productObj.uuid = params["uuid"];
    params["status"] = urlParams.get('status');
  }



  function onChangeWalletAddress(address) {
    setWalletAddress(address);
  }



  function addWalletListener() {
    // console.log('addWalletListener():::2022-07-13');
    // console.log('window.ethereum:::2022-07-13', window.ethereum);
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        // console.log('accounts:::2022-07-13', accounts);
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
        } else {
          setWalletAddress("");
        }
      });
    }
  }



  const connectWalletPressed = async () => {
    console.log('connectWalletPressed:::2022-07-13');
    const walletResponse = await connectWallet();
    console.log('walletResponse:::2022-07-13', walletResponse);
    onChangeWalletAddress(walletResponse.address)
  };


  async function connectWallet() {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        console.log('err::2022-05-20', err);
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
  
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`} rel="noreferrer">
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };




  const getCurrentWalletConnected = async () => {
    // console.log('getCurrentWalletConnected:::2022-07-13');
    var address = '';

    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });

        if (addressArray.length > 0) {
          address = addressArray[0];
        }

      } catch (err) {
        address = '';

      }
    }

    setWalletAddress(address);
  };




  const handleFormChange = (event) => {
    console.log('handleFormChange:::2022-06-27');
    setWalletAddress(event.target.value);
  }


  const handleSubmitWalletAddress = (event) => {
    console.log('handleSubmitWalletAddress:::2022-07-13');
    console.log("event:::2022-07-13", event);
    console.log("event.target:::2022-07-13", event.target);
    console.log("event.target.pasteWalletAddress.value:::2022-07-13", event.target.pasteWalletAddress.value);
    setWalletAddress(event.target.pasteWalletAddress.value);
    event.preventDefault();
  }


  return (
    <div className={styles.backgroundOverlay}>
      <MinterTemplate
        claimProductResponse={claimProductResponse}
        isFetching={isFetching}
        nftDetails={nftDetails}
        order={order}
        params={params}
        product={product}
        store={store}
        walletAddress={walletAddress}
        
        connectWalletPressed={connectWalletPressed}
        handleFormChange={handleFormChange}
        handleSubmitWalletAddress={handleSubmitWalletAddress}
        onChangeWalletAddress={onChangeWalletAddress}
      ></MinterTemplate>
    </div>
  );
}



export default Minter;