import React from "react";
import styles from './CustomMetadata.module.css';



function CustomMetadata(props) {

  const store = props.store;
  const product = props.product


  if (store.fulfilment.custom_metadata_type === "automatic") {
    return (
      <>
        <div>
            <p className={styles.attributeTitle}>Custom Metadata</p>
            <p className={`
              ${styles.attributeDescription}
              ${styles.capitalize}
            `}>
              Custom metadata not set.
            </p>
          </div>
      </>
    );    
  }

  if (store.fulfilment.custom_metadata_type === "manual") {
    return (
      <>
        <div>
          <p className={styles.attributeTitle}>Custom Metadata</p>

          <DisplayMetadata
            store={store}
            product={product}
          ></DisplayMetadata>

        </div>

      </>
    );    
  }

}



function DisplayMetadata(props) {
  const store = props.store;
  const product = props.product;

  var myMetadata = product.custom_metadata.map((item, index) => 
    <p 
      key={index} 
      className={`
        ${styles.attributeDescription}
        ${styles.mb0}
      `}
    >{ `Title: ${item.title}, Value: ${item.value}` }</p>
  );

  let storeChecker = [
    "my-shop-example-1.myshopify.com",
    "certify-watches.myshopify.com",
  ];

  if (storeChecker.includes(store.store_url)) {
    return (
      <>
        <DisplayMetadataHidden
          product={product}
        ></DisplayMetadataHidden>
        { myMetadata }
      </>
    );

  } else {
    if (product.custom_metadata === null) {
      return (
        <p className={styles.attributeDescription}>No data</p>
      );
    }

    return (
      <>
        { myMetadata }
      </>
    );

  }

}



function DisplayMetadataHidden(props) {
  const product = props.product;

  let metadata_1 = product.manual_fulfilment.title;
  let metadata_2 = product.manual_fulfilment.vendor;
  let metadata_3 = product.manual_fulfilment.purchase_date;

  return (
    <>
      <p
        className={`
          ${styles.attributeDescription}
          ${styles.mb0}
        `}
      >Title: Brand, Value: { metadata_1 }</p>
      <p
        className={`
          ${styles.attributeDescription}
          ${styles.mb0}
        `}
      >Title: Model, Value: { metadata_2 }</p>
      <p
        className={`
          ${styles.attributeDescription}
          ${styles.mb0}
        `}
      >Title: Reference Number, Value: { metadata_3 }</p>
    </>
  );
}



export default CustomMetadata;